import React, { ChangeEvent, useEffect, useState } from 'react'

import { RangeInputContainer } from './style'

type RangeInputProps = {
  minValue: number;
  maxValue: number;
  interval: number;
  onChange: Function;
  initialValue?: number;
}
function currencyToFloat (currencyNumber: string) {
  const result = currencyNumber === '' ? 0 : parseInt(currencyNumber.replace(/\D/g, ''), 10)
  return result
}

const RangeInput = ({ minValue, maxValue, interval, onChange, initialValue }: RangeInputProps) => {
  const [ rangeValue, setRangeValue ] = useState<number>(initialValue ? initialValue : 0)
  const [ percentage, setPercentage ] = useState<number>(0)

  function calculatePercentage () {
    const range = Math.round(maxValue - minValue)
    return (rangeValue - minValue) * 100 / range
  }
  const changeRange = (event: ChangeEvent<HTMLInputElement>) => {
    setRangeValue(currencyToFloat(event.target.value))
    onChange(event.target.value)
    setPercentage(calculatePercentage())
  }

  useEffect(() => {
    setPercentage(calculatePercentage())
  }, [])

  return (
    <RangeInputContainer>
      <span className='range-slider'>
        <input
          type='range'
          name='range'
          min={minValue}
          max={maxValue}
          step={interval}
          value={rangeValue}
          onChange={changeRange}
        />
        <span className='slider-container'>
          <span className='bar'>
            <span style={{ width: `${percentage}%` }} />
          </span>
          <span className='bar-btn' style={{ left: `${percentage}%` }} />
        </span>
      </span>
    </RangeInputContainer>
  )
}

export default RangeInput
